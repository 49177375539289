<template>
    <b-container>
        <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login" modal-class="modal-session-app"
            centered title="Expired!" @ok="gotologin">
            <b-card-text>Session Expired</b-card-text>
        </b-modal>
        <b-alert show class="text-center" v-if="getted" style="padding: 5px 10px" :variant="variant">
            <span v-html="message"></span>
        </b-alert>
        <b-skeleton v-else></b-skeleton>

        <b-card style="height: 200px">
            <div class="mt-1 mt-md-2 mt-lg-2 mt-xl-2 search-input-dev mx-auto">
                <b-card-text class="text-center" style="font-size: 22px; font-weight: bold; padding-top: 10px;">
                    <b-img :src="appLogoImage" alt="" width="28" style="padding-bottom: 8px;" />
                    Applead: ASO Tools for Android Apps.
                </b-card-text>
                <b-form-group>
                    <b-form-input type="text" placeholder="Search for apps & games" id="search-input"
                                v-model="search" @focus="handleFocus" @blur="handleBlur" />
                    <small ref="searchError" class="text-danger"></small>
                    <ul v-if="suggest && inputFocused && suggestsArray.length > 0" class="list-group position-relative w-100" style="z-index: 10">
                        <li v-for="item in suggestsArray" :key="item.term" class="list-group-item cursor-pointer"
                            @mousedown.prevent="selectApp(item)">
                            {{ item.term }}
                        </li>
                    </ul>
                    <ul v-if="suggestsArray.length === 0 && !suggest && inputFocused && search.trim() !== '' && !loading"
                        class="list-group position-relative w-100" style="z-index: 10">
                        <li class="list-group-item cursor-pointer">No data available</li>
                    </ul>
                </b-form-group>
            </div>
        </b-card>

        <b-row class="match-height">
            <b-col xs="12" sm="6" md="4" lg="3" v-for="item in functionsItems" :key="item.title">
                <b-card class="custom-card" @click="redirectToRoute(item.linkName)">
                    <div class="d-flex justify-content-end mb-1">
                        <span :class="`btn typeBadges rounded-pill btn-outline-${item.color}`">{{ item.type }}</span>
                    </div>
                    <span class="icon-container mb-2">
                        <feather-icon :icon="item.icon" class="hover-icon" />
                    </span>
                    <b-card-text class="card-text-title">{{ item.title }}</b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { BFormGroup, BFormInput, BContainer, BCard, BCardText, BImg, BRow, BCol, BModal, BAlert, BSkeleton } from "bootstrap-vue";
import axios from "@axios";
import { connectApi } from "@/helper/connectApi";
import { deleteAccessTokenCookie } from "@/helper/helpers.js";
import { $themeConfig } from "@themeConfig";

export default {
    components: { BFormGroup, BFormInput, BContainer, BCard, BCardText, BImg, BRow, BCol, BModal, BAlert, BSkeleton },
    setup() {
        return { appLogoImage: $themeConfig.app.appLogoImage };
    },
    data() {
        return {
            message: "",
            variant: "",
            getted: false,
            search: "",
            suggestsArray: [],
            suggest: false,
            inputFocused: false,
            blurTimeout: null,
            loading: false,
            functionsItems: [
                { title: "Market Search", type: "Free", color: "success", linkName: "market-manager-market-search", icon: "ShoppingBagIcon" },
                { title: "Account Search", type: "Free", color: "success", linkName: "market-manager-accounts-search", icon: "UserIcon" },
                { title: "Keyword Suggests", type: "Free", color: "success", linkName: "keyword-manager-suggestions", icon: "KeyIcon" },
                { title: "Top Charts", type: "Free", color: "success", linkName: "top-apps-charts", icon: "TrendingUpIcon" },
                { title: "Keyword Search", type: "Premium", color: "warning", linkName: "keyword-manager-keyword-search", icon: "SearchIcon" },
                { title: "Keyword Search Max", type: "Premium", color: "warning", linkName: "keyword-manager-keyword-search", icon: "ZoomInIcon" },
                { title: "Niches", type: "Premium", color: "warning", linkName: "niche-manager", icon: "TargetIcon" },
                { title: "Source Codes", type: "Premium", color: "warning", linkName: "marketplace", icon: "CodeIcon" }
            ],
        };
    },
    watch: {
        search(newVal) {
            if (newVal.trim()) this.getSuggestions(newVal); else this.resetSuggestions();
        },
    },
    mounted() {
        this.checkUserPlan();
        this.checkPlanExpiration();
    },
    methods: {
        checkPlanExpiration() {
            axios.post("/plan-expiration").then(({ data }) => {
                this.getted = true;
                this.variant = data.plan === "basic" ? (data.deleted ? "warning" : "danger") : "success";
                this.message = data.plan === "basic" && data.deleted ?
                    `You have unpaid invoices 📝, <a class="payment" href="/pages/payment-method?same_id=${data.invoice_id}&plan_id=1">Pay your invoice </a> to unlock Pro functions` :
                    data.plan === "basic" ?
                        `You are in the basic plan, <a href="/pages/plan-selection"> Upgrade 🚀 </a> to unlock Pro Functions` :
                        "Congratulations 🎉, you've successfully upgraded to use all Pro Functions";
            }).catch(() => { this.getted = true });
        },
        getSuggestions(query) {
            this.loading = true;
            connectApi(`https://scx.clicklab.app/api/apps/?suggest=${query}`).then(({ data }) => {
                this.loading = false;
                if (data.status === "Unauthorized") {
                    deleteAccessTokenCookie();
                    this.$root.$emit("bv::show::modal", "modal-session-app");
                } else {
                    this.suggest = true;
                    this.suggestsArray = data.results || [];
                }
            }).catch((error) => {
                console.error(error);
                this.suggestsArray = [];
                this.suggest = false;
                this.loading = false;
            });
        },
        checkUserPlan() {
            axios.post("/checkUserSubscription").then(({ data }) => this.IsPro = data.isPro).catch(() => this.IsPro = false);
        },
        handleFocus() {
            clearTimeout(this.blurTimeout);
            this.inputFocused = true;
        },
        handleBlur() {
            this.blurTimeout = setTimeout(() => {
                this.inputFocused = false;
            }, 200);
        },
        selectApp(app) {
            clearTimeout(this.blurTimeout);
            this.resetSuggestions();
            this.search = app.term;
            this.inputFocused = false;
            this.$router.push(`/market-manager/applications-search?search=${this.search}`);
        },
        resetSuggestions() {
            this.suggest = false;
            this.suggestsArray = [];
        },
        redirectToRoute(routeName) {
            this.$router.push({ name: routeName });
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },
    },
};
</script>

<style scoped>


.search-input-dev {
    width: 70%;
}

.custom-card {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background-color 0.3s ease, color 0.3s;
    cursor: pointer;
}

.custom-card:hover {
    background-color: #167fcd;
    color: white;
}

.icon-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #157fcf;
}

.hover-icon {
    width: 115px;
    height: 115px;
    transition: transform 0.3s;
}

.custom-card:hover .hover-icon {
    transform: scale(1.2);
    color: white;
}

.card-text-title {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

.typeBadges {
    padding: 3px 10px;
}

@media (max-width: 675px) {
    .search-input-dev {
        width: 100%;
    }
}
</style>
